// Core
import { useMemo } from 'react';
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';

// Antd
import { Button } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';

// App
import images from '~/assets/images';

// Component
const RouterErrorBoundary = () => {
    // Hooks
    const error = useRouteError();
    const isRouteError = isRouteErrorResponse(error);

    // Router error
    const routerError = useMemo(() => {
        if (isRouteError && error.status === 404) {
            return {
                image: images.image404,
                message: 'NOT FOUND PAGE',
                description: "OOPS ! The page you are looking for can't be found.",
            };
        }

        if (isRouteError && error.status === 403) {
            return {
                image: images.image505,
                message: 'SEVER ERROR PAGE',
                description: 'OOPS ! The page has error.',
            };
        }

        return {
            image: images.image505,
            message: 'SEVER ERROR PAGE',
            description: 'OOPS ! The page has error.',
        };
    }, [isRouteError, error]);

    // Template
    return (
        <div className="flex h-screen w-screen items-center justify-center py-10 font-beVietnamPro">
            <div className="container">
                <div className="flex flex-col items-center justify-center lg:flex-row gap-6">
                    <img src={routerError.image} alt={routerError.message} className="w-1/2 lg:w-1/3" />
                    <div className="flex flex-col items-center gap-2 text-center font-BeVietNamBold">
                        <h2 className="text-3xl lg:text-4xl text-hotlink-yellow-f6ae2d font-bold">
                            {routerError.message}
                        </h2>
                        <h3 className="px-2 gap-2 text-lg lg:text-xl text-gray-300 font-medium">
                            {routerError.description}
                        </h3>
                        <Link to={'/'}>
                            <Button
                                type="primary"
                                icon={<HistoryOutlined />}
                                className="mt-4 px-4 py-6 text-xl bg-hotlink-yellow-f6ae2d rounded-full hover:bg-hotlink-yellow-f6ae2d/50"
                            >
                                Go Back Home
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RouterErrorBoundary;
