// Core
import cn from 'classnames';
import { Input, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addTopic, removeTopic } from '~/redux/globalSearchSlice';
import { useNavigate } from 'react-router-dom';

const { Search } = Input;

const tagList = [
    {
        id: 'dictionary',
        name: 'Dictionary',
    },
    {
        id: 'test',
        name: 'Test',
    },
    {
        id: 'news',
        name: 'News',
    },
];

// Components
const GlobalSearch = ({ open, onClose = () => {} }) => {
    // Hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const topicList = useSelector((state) => state.globalSearch.topicSelectedList);

    // Functions
    const handleSearch = (value) => {
        navigate(`/search?keyword=${value.trim()}`);
        onClose();
    };

    const handleAddTopic = (topic) => {
        dispatch(addTopic(topic));
    };

    const handleRemoveTopic = (topic) => {
        dispatch(removeTopic(topic));
    };

    // Template
    return (
        <div
            className={cn(
                'absolute inset-x-0 w-full rounded-b-lg transform transition-all z-50 overflow-y-hidden',
                open ? 'shadow-md border-t-2' : 'shadow-none',
            )}
            style={{
                top: '100%',
                height: open ? '180px' : '0',
                backgroundColor: '#fff',
                overflowY: 'hidden',
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <div className="absolute inset-0 flex flex-col">
                <div className="p-2 flex justify-end items-center border-b">
                    <button onClick={onClose} className="text-sm text-gray-500 hover:text-hotlink-yellow-f6ae2d">
                        ✖
                    </button>
                </div>
                <div className="px-4 py-2">
                    <div className="flex flex-wrap space-x-2 mb-2">
                        {topicList.map((topic) => (
                            <Tag
                                key={topic.id}
                                className="px-2 py-1 text-md font-medium bg-hotlink-yellow-f6ae2d transition-colors duration-200 cursor-pointer hover:bg-yellow-200"
                                bordered={false}
                                closable={topicList.length > 1 ? true : false}
                                onClose={() => handleRemoveTopic(topic)}
                            >
                                {topic.name}
                            </Tag>
                        ))}
                    </div>
                    <Search placeholder="input search text" allowClear onSearch={handleSearch} />
                    <div className="flex flex-wrap space-x-2 mt-2">
                        {tagList.map((tag) => (
                            <Tag
                                key={tag.id}
                                className="px-2 py-1 text-md font-medium bg-hotlink-yellow-f6ae2d transition-colors duration-200 cursor-pointer hover:bg-yellow-200"
                                bordered={false}
                                onClick={() => handleAddTopic(tag)}
                            >
                                {tag.name}
                            </Tag>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GlobalSearch;
