// Core
import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

// App
import { ErrorBoundary, Loading } from '~/components/shared';
import { ExamLayout } from '~/layouts';

// Loader
import { requireAuth } from './loaders/auth';
import paths from '../constants/paths';

// Pages
const HomePage = lazy(() => import('~/pages/HomePage'));
const LoginPage = lazy(() => import('~/pages/modules/auth/LoginPage'));
const News = lazy(() => import('~/pages/NewsPage'));
// const AllNews = lazy(() => import('~/pages/News/AllNews'));
// const NewsDetail = lazy(() => import('~/pages/News/AllNews/NewsDetails/NewsDetail'));
const ExamPracticePage = lazy(() => import('~/pages/modules/exam/ExamPracticePage'));
const DictionaryPage = lazy(() => import('~/pages/DictionaryPage'));
const RegisterPage = lazy(() => import('~/pages/modules/auth/RegisterPage'));
const RegisterVerifyPage = lazy(() => import('~/pages/modules/auth/RegisterVerifyPage'));
const ExamBankPage = lazy(() => import('~/pages/modules/exam/ExamBankPage'));
const FlashCardPage = lazy(() => import('~/pages/FlashCardPage'));
// const FlashCardList = lazy(() => import('~/pages/FlashCard/FlashCardList'));
// const FlashCardReview = lazy(() => import('~/pages/FlashCard/FlashCardReview'));
const ForgotPasswordPage = lazy(() => import('~/pages/modules/auth/ForgotPasswordPage'));
const OTPVerifyPage = lazy(() => import('~/pages/modules/auth/OTPVerifyPage'));
const ChangePasswordPage = lazy(() => import('~/pages/modules/auth/ChangePasswordPage'));
const SearchResultPage = lazy(() => import('~/pages/SearchResultPage'));

// Layouts
const DefaultLayout = lazy(() => import('~/layouts/DefaultLayout'));
const AdminLayout = lazy(() => import('~/layouts/AdminLayout'));

// Admin Pages
const ExamListPage = lazy(() => import('~/pages/modules/admin/Exam/ExamListPage'));
const ExamCreationPage = lazy(() => import('~/pages/modules/admin/Exam/ExamCreationPage'));
const AccountListPage = lazy(() => import('~/pages/modules/admin/Account/AccountListPage'));
const ManageStatisticPage = lazy(() => import('~/pages/modules/admin/ManageStatisticPage'));
const NewsListPage = lazy(() => import('~/pages/modules/admin/News/NewsListPage'));
const NewsCreationPage = lazy(() => import('~/pages/modules/admin/News/NewsCreationPage'));
const FeedbackListPage = lazy(() => import('~/pages/modules/admin/Feedback/FeedbackListPage'));
const ManageMockTestPage = lazy(() => import('~/pages/modules/admin/ManageMockTestPage'));

// Profile Pages
const ProfilePage = lazy(() => import('~/pages/ProfilePage'));
// Speaking Pages

//const SpeakingIntro = lazy(() => import('~/pages/Speaking/SpeakingIntro/Speaking'));
const SpeakingExamPage = lazy(() => import('~/pages/Speaking/SpeakingExamPage/SpeakingExamPage'));

// Other Pages
// const ForumPage = lazy(() => import('~/pages/ForumPage'));
// const Result = lazy(() => import('~/pages/Result'));

// Layout Components
// const ProfileLayout = lazy(() => import('~/layouts/ProfileLayout/ProfileLayout'));
// const ForumLayout = lazy(() => import('~/layouts/ForumLayout'));

const routers = createBrowserRouter([
    {
        path: paths.home,
        element: <DefaultLayout />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<Loading />}>
                        <HomePage />
                    </Suspense>
                ),
            },
            {
                path: paths.dictionary,
                element: (
                    <Suspense fallback={<Loading />}>
                        <DictionaryPage />
                    </Suspense>
                ),
            },
            {
                loader: requireAuth,
                path: paths.news,
                element: (
                    <Suspense fallback={<Loading />}>
                        <News />
                    </Suspense>
                ),
            },
            {
                loader: requireAuth,
                path: paths.speakingExam,
                element: (
                    <Suspense fallback={<Loading />}>
                        <SpeakingExamPage />
                    </Suspense>
                ),
            },
            {
                path: paths.flashCard,
                element: (
                    <Suspense fallback={<Loading />}>
                        <FlashCardPage />
                    </Suspense>
                ),
            },
            {
                loader: requireAuth,
                path: paths.profile,
                element: (
                    <Suspense fallback={<Loading />}>
                        <ProfilePage />
                    </Suspense>
                ),
            },
            {
                path: paths.search,
                element: (
                    <Suspense fallback={<Loading />}>
                        <SearchResultPage />
                    </Suspense>
                ),
            },
            {
                path: 'exam',
                element: (
                    <Suspense fallback={<Loading />}>
                        <ExamBankPage />
                    </Suspense>
                ),
            },
            {
                path: 'exam/results/:id',
                lazy: () => {
                    const ExamResultPage = lazy(() => import('~/pages/modules/exam/ExamResultPage'));

                    return {
                        element: (
                            <Suspense fallback={<Loading />}>
                                <ExamResultPage />
                            </Suspense>
                        ),
                    };
                },
            },
        ],
    },
    {
        path: paths.login,
        errorElement: <ErrorBoundary />,
        element: (
            <Suspense fallback={<Loading />}>
                <LoginPage />
            </Suspense>
        ),
    },
    {
        path: paths.register,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '',
                element: (
                    <Suspense fallback={<Loading />}>
                        <RegisterPage />
                    </Suspense>
                ),
            },
            {
                path: 'confirm',
                element: (
                    <Suspense fallback={<Loading />}>
                        <RegisterVerifyPage />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: 'exam/practice/:id',
        element: <ExamLayout />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<Loading />}>
                        <ExamPracticePage />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: paths.forgotPassword,
        errorElement: <ErrorBoundary />,
        element: (
            <Suspense fallback={<Loading />}>
                <ForgotPasswordPage />
            </Suspense>
        ),
    },
    {
        path: paths.verifyOtp,
        errorElement: <ErrorBoundary />,
        element: (
            <Suspense fallback={<Loading />}>
                <OTPVerifyPage />
            </Suspense>
        ),
    },
    {
        path: paths.resetPassword,
        errorElement: <ErrorBoundary />,
        element: (
            <Suspense fallback={<Loading />}>
                <ChangePasswordPage />
            </Suspense>
        ),
    },
    {
        path: '/admin',
        element: <AdminLayout />,
        loader: requireAuth,
        errorElement: <ErrorBoundary />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<Loading />}>
                        <ManageStatisticPage />
                    </Suspense>
                ),
            },
            {
                path: paths.admin.statistics,
                element: (
                    <Suspense fallback={<Loading />}>
                        <ManageStatisticPage />
                    </Suspense>
                ),
            },
            {
                path: 'exam',
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<Loading />}>
                                <ExamListPage />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'creation',
                        element: (
                            <Suspense fallback={<Loading />}>
                                <ExamCreationPage />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: paths.admin.user,
                element: (
                    <Suspense fallback={<Loading />}>
                        <AccountListPage />
                    </Suspense>
                ),
            },
            {
                path: 'news',
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<Loading />}>
                                <NewsListPage />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'creation',
                        element: (
                            <Suspense fallback={<Loading />}>
                                <NewsCreationPage />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: paths.admin.feedback,
                element: (
                    <Suspense fallback={<Loading />}>
                        <FeedbackListPage />
                    </Suspense>
                ),
            },
            {
                path: paths.admin.test,
                element: (
                    <Suspense fallback={<Loading />}>
                        <ManageMockTestPage />
                    </Suspense>
                ),
            },
        ],
    },
]);

export default routers;
