// Core
import { useCallback, useContext, useMemo } from 'react';

// Antd
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { NotificationContext } from '../components/ui/Notification';

// Notification Configuration
const notificationConfig = {
    success: {
        colorClass: 'text-green-700',
        bgClass: 'bg-green-100',
        borderClass: 'border-green-500',
        icon: <CheckCircleOutlined className="text-green-500" />,
    },
    error: {
        colorClass: 'text-red-700',
        bgClass: 'bg-red-100',
        borderClass: 'border-red-500',
        icon: <CloseCircleOutlined className="text-red-500" />,
    },
    warning: {
        colorClass: 'text-yellow-700',
        bgClass: 'bg-yellow-100',
        borderClass: 'border-yellow-500',
        icon: <ExclamationCircleOutlined className="text-yellow-500" />,
    },
};

// Custom Hook
const useNotification = () => {
    const { api } = useContext(NotificationContext);

    const renderContent = useCallback((text, type, isMessage = true) => {
        const className = isMessage
            ? `ml-3 text-lg font-bold font-beVietnamPro ${notificationConfig[type].colorClass}`
            : `ml-3 text-sm font-medium font-beVietnamPro ${notificationConfig[type].colorClass}`;
        return <div className={className}>{text}</div>;
    }, []);

    const notificationApi = useMemo(() => {
        if (!api) {
            console.warn('Notification API is not available');
            return {};
        }

        return Object.keys(notificationConfig).reduce((acc, type) => {
            acc[type] = ({ message, description }) => {
                const { colorClass, bgClass, borderClass, icon } = notificationConfig[type];
                api[type]({
                    message: renderContent(message, type, true),
                    description: renderContent(description, type, false),
                    placement: 'top',
                    icon,
                    className: `${colorClass} ${bgClass} ${borderClass} p-3 rounded-lg shadow-md`,
                });
            };
            return acc;
        }, {});
    }, [api, renderContent]);

    return notificationApi;
};

export default useNotification;
