const paths = {
    home: '/',
    login: '/login',
    register: '/register',
    registerConfirm: '/register/confirm',
    test: '/test',
    testInfo: '/test/test-guide',
    testToeic: '/test/toeic-test',
    testToeicPractice: '/test/toeic-test/practice',
    testResultDetail: '/test/detail',

    examRepository: '/exam-repository',
    examPractice: '/exam/practice',

    news: '/news',
    newsAll: '/all-news',
    newsDetail: '/news-detail/:id',
    forum: '/news/forum',

    dictionary: '/dictionary',
    speakingIntro: '/exam-repository/speaking',
    speakingExam: '/speaking-exam',

    flashCard: '/flash-card',
    flashCardList: '/flash-card/list',
    flashCardReview: '/flash-card/list/review',

    profile: '/profile',
    profileEdit: '/profile/change-profile',
    profileChangePassword: '/profile/change-pass',

    forgotPassword: '/forget-password',
    verifyOtp: '/verify-otp',
    resetPassword: '/change-password',

    search: '/search',
    setting: '/setting',

    // Admin paths
    admin: {
        exam: 'exam',
        user: 'user',
        news: 'news',
        feedback: 'feedback',
        test: 'test',
        statistics: 'statistic',
    },
};

export default paths;
