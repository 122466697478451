import DualBallLoading from '~/assets/svg/DualBallLoading.svg';

function Loading() {
    return (
        <div className="fixed top-0 left-0 h-screen w-screen bg-gray-300/50 z-[100]">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <img src={DualBallLoading} alt="loading" className="w-20 h-20" />
            </div>
        </div>
    );
}
export default Loading;
