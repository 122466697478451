import axios from '../axios';

export const logout = async (payload, config) => {
    try {
        const response = await axios.post('/auth/logout', payload, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUserInformation = async (axiosPrivate) => {
    try {
        const response = await axiosPrivate.get('user/information');
        const userInfoResponse = response.data?.responseData;

        return {
            ...userInfoResponse.user,
            role: userInfoResponse.role,
        };
    } catch {
        throw new Error();
    }
};

export const listExamResultsUser = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .get('/listExamResultsUser', payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const changeProfile = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .patch(`/changeProfile`, payload, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const changePass = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .patch(`/auth/changePass`, payload, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const loginUser = async (payload) => {
    try {
        const response = await axios.post('/auth/login', { ...payload });
        const loginResponse = response.data;

        if (loginResponse?.responseData) {
            return {
                message: 'Success',
                description: 'Login success',
                accessToken: loginResponse.responseData.accessToken,
                refreshToken: loginResponse.responseData.refreshToken,
                userInfo: loginResponse.responseData.userAuth,
            };
        } else {
            return {
                message: 'Failed',
                description: loginResponse.message ?? 'Login failed',
            };
        }
    } catch (error) {
        throw error;
    }
};

export const registerUser = async (payload) => {
    try {
        const response = await axios.post('/auth/register', { ...payload });
        console.log(response);
        const registerResponse = response.data;

        return {
            message: 'Success',
            description: registerResponse.message ?? 'Register success',
        };
    } catch (error) {
        return {
            message: 'Failed',
            description: error.response.data.message ?? 'Register failed',
        };
    }
};

export const confirmRegister = async (token) => {
    try {
        const response = await axios.get(`/auth/register/confirm?token=${token}`);
        const confirmResponse = response.data;

        if (confirmResponse?.success) {
            return {
                success: true,
                title: 'Thành công',
                description: 'Xác nhận đăng ký thành công',
            };
        } else {
            return {
                success: false,
                title: 'Thất bại',
                description: 'Xác nhận đăng ký thất bại',
            };
        }
    } catch {
        throw new Error('Xác nhận đăng ký thất bại');
    }
};

export const authSendOTP = async (email) => {
    try {
        const response = await axios.get(`/auth/mailer/otp?email=${encodeURIComponent(email)}`);
        const sendOTPResponse = response.data;

        return {
            success: sendOTPResponse.success,
            message: sendOTPResponse.message,
        };
    } catch (error) {
        if (error.response && error.response.data) {
            throw error.response.data;
        }

        throw new Error('Đã xảy ra lỗi không xác định');
    }
};

export const authLogOut = async () => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        if (accessToken !== null && refreshToken !== null) {
            const response = await logout(
                {
                    refreshToken,
                },
                {
                    withCredentials: true,
                },
            );
            if (response.status === 'OK') {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('ROLE');
            }
        }
    } catch (err) {
        throw new Error();
    }
};

export const authVerifyOTP = async (otpCode) => {
    try {
        const response = await axios.get(`/auth/verify/otp?otp=${encodeURIComponent(otpCode)}`);
        const verifyOTPResponse = response.data;

        return {
            message: 'Thành Công',
            description: verifyOTPResponse.message,
        };
    } catch (error) {
        console.log(error);
        throw new Error(error.response?.data?.message ?? 'Xác thực OTP xảy ra lỗi');
    }
};

export const authForgotPassword = async ({ otpCode, newPassword, confirmNewPassword }) => {
    try {
        const response = await axios.post('/auth/change/password/forgot', { otpCode, newPassword, confirmNewPassword });
        const changePasswordResponse = response.data;

        return {
            message: 'Thành Công',
            description: changePasswordResponse.message,
        };
    } catch (error) {
        throw new Error(error.response?.data?.message ?? 'Thay đổi mật khẩu xảy ra lỗi');
    }
};

export const authChangePassword = async ({ otpCode, oldPassword, newPassword, confirmNewPassword }) => {
    try {
        const response = await axios.post('/auth/change/password', {
            otpCode,
            oldPassword,
            newPassword,
            confirmNewPassword,
        });
        const changePasswordResponse = response.data;

        return {
            message: 'Thành Công',
            description: changePasswordResponse.message,
        };
    } catch (error) {
        throw new Error(error.response?.data?.message ?? 'Thay đổi mật khẩu xảy ra lỗi');
    }
};
