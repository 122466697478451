import { BellOutlined } from '@ant-design/icons';
import { Avatar, Badge, Empty, Popover } from 'antd';

const NotificationBell = () => {
    return (
        <Popover
            content={
                <ul className="">
                    <Empty />
                </ul>
            }
            placement="bottomLeft"
            trigger={'click'}
        >
            <Badge count={5} styles={{ indicator: { boxShadow: 'none' } }}>
                <Avatar icon={<BellOutlined />} className="cursor-pointer" />
            </Badge>
        </Popover>
    );
};

export default NotificationBell;
