// NotificationContext.js
import React, { createContext } from 'react';
import { notification } from 'antd';

export const NotificationContext = createContext(null);

export const NotificationProvider = ({ children }) => {
    const [api, contextHolder] = notification.useNotification();

    return (
        <NotificationContext.Provider value={{ api }}>
            {contextHolder}
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
