const HighlightedText = ({ text, searchValue }) => {
    if (!searchValue) {
        return <span>{text}</span>;
    }

    const regex = new RegExp(`(${searchValue})`, 'gi');
    const parts = text.split(regex);

    return (
        <span>
            {parts.map((part, index) =>
                regex.test(part) ? (
                    <span key={index} className="text-hotlink-yellow-f6ae2d">
                        {part}
                    </span>
                ) : (
                    <span key={index}>{part}</span>
                ),
            )}
        </span>
    );
};

export default HighlightedText;
