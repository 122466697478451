import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    topicSelectedList: [
        {
            id: 'dictionary',
            name: 'Dictionary',
        },
    ],
};

const globalSearchSlice = createSlice({
    name: 'globalSearch',
    initialState,
    reducers: {
        addTopic: (state, action) => {
            if (!state.topicSelectedList.find((topic) => topic.id === action.payload.id)) {
                state.topicSelectedList.push(action.payload);
            }
        },
        removeTopic: (state, action) => {
            if (state.topicSelectedList.length > 1) {
                state.topicSelectedList = state.topicSelectedList.filter((topic) => topic.id !== action.payload.id);
            }
        },
    },
});

export const { addTopic, removeTopic } = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
