import { Pagination as PaginationAntd } from 'antd';
import { useSearchParams } from 'react-router-dom';

const Pagination = ({ paginationData, onPageChange = () => {} }) => {
    const { pageNumber, pageSize, totalPages, hasPreviousPage, hasNextPage } = paginationData;

    const [, setSearchParams] = useSearchParams();

    const handleChange = (page, pageSize) => {
        onPageChange(page, pageSize);
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('page', page);
            return newParams;
        });
    };

    return (
        <PaginationAntd
            current={pageNumber}
            pageSize={pageSize}
            total={totalPages * pageSize}
            showSizeChanger={false}
            onChange={handleChange}
            disabled={!hasPreviousPage && !hasNextPage}
        />
    );
};

export default Pagination;
