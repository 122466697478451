import { Outlet, useNavigate } from 'react-router-dom';
import { Footer } from '../components';
import { Header } from './components';
import { useEffect } from 'react';

const ExamLayout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        const handleBackButton = () => {
            const confirmLeave = window.confirm(
                'Bạn có chắc muốn thoát khỏi bài kiểm tra không? Kết quả sẽ không được lưu!',
            );
            if (!confirmLeave) {
                navigate(1);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate]);

    return (
        <div className="flex flex-col grid min-h-screen bg-gradient-to-b from-[#f9f9ff] to-[#ffffff]">
            <Header />
            <main className="flex-grow px-4 sm:px-8 md:px-16 lg:px-24 py-6">
                <Outlet />
            </main>
            <Footer />
        </div>
    );
};

export default ExamLayout;
