// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-contact {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
}

.border-contact {
  border: solid 1px #d9d9d9;
}

.contact-page {
  font-family: "Be Vietnam Pro", sans-serif;
}
.contact-page .font-semibold {
  font-weight: 600;
}
.contact-page .font-bold {
  font-weight: 700;
}
.contact-page .text-base {
  font-weight: 400;
}
.contact-page .text-3xl,
.contact-page .text-4xl {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Contact/Contact.scss"],"names":[],"mappings":"AAAA;EACI,4CAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AACA;EACI,yCAAA;AAEJ;AAAI;EACI,gBAAA;AAER;AAAI;EACI,gBAAA;AAER;AAAI;EACI,gBAAA;AAER;AAAI;;EAEI,gBAAA;AAER","sourcesContent":[".shadow-contact {\r\n    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);\r\n}\r\n\r\n.border-contact {\r\n    border: solid 1px #d9d9d9;\r\n}\r\n.contact-page {\r\n    font-family: 'Be Vietnam Pro', sans-serif;\r\n\r\n    .font-semibold {\r\n        font-weight: 600; \r\n    }\r\n    .font-bold {\r\n        font-weight: 700;\r\n    }\r\n    .text-base {\r\n        font-weight: 400;\r\n    }\r\n    .text-3xl,\r\n    .text-4xl {\r\n        font-weight: 700; \r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
