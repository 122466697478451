const images = {
    logo: require('~/assets/images/MeU.png'),
    noImage: require('~/assets/images/no-image.png'),
    newsImage: require('~/assets/images/news-image.png'),
    introImage: require('~/assets/images/Intro.png'),
    contactUs: require('~/assets/images/contact-us.png'),
    zalo: require('~/assets/images/AvatarZalo.png'),
    facebook: require('~/assets/images/avatarFb.png'),
    google: require('~/assets/images/avatarGG.png'),
    test_banner: require('~/assets/images/test-banner.png'),
    avatar_flashCard: require('~/assets/images/image.png'),
    avatarpr: require('~/assets/images/news-image2.png'),
    phongvanimg: require('~/assets/images/phongvan.jpeg'),
    luyenthi: require('~/assets/images/luyenthi.png'),
    image404: require('~/assets/images/image404.png'),
    image505: require('~/assets/images/500Error.png'),
    banner2: require('~/assets/images/banner2.png'),
    banner3: require('~/assets/images/banner3.jpeg'),
};

export default images;
