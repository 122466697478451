import { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

const NavigationMenu = ({ children }) => {
    const [menuList, setMenuList] = useState([]);

    const updateActiveItem = (id) => {
        setMenuList((prev) =>
            prev.map((item) => ({
                ...item,
                active: item.id === id,
            })),
        );
    };

    return (
        <NavigationContext.Provider value={{ menuList, setMenuList, updateActiveItem }}>
            <div className="relative flex-1 lg:flex w-full h-full justify-center items-center">{children}</div>
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }
    return context;
};

export default NavigationMenu;
