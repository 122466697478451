import { Children, useEffect } from 'react';
import { useNavigation } from '../NavigationMenu';

const NavigationMenuList = ({ children }) => {
    const { setMenuList } = useNavigation();

    useEffect(() => {
        const initialMenuList = Children.map(children, (child) => ({
            id: child.props.id,
            active: false,
        }));
        setMenuList(initialMenuList);
    }, [children, setMenuList]);

    return <ul className="h-full flex items-center gap-2">{children}</ul>;
};

export default NavigationMenuList;
