import { Link } from 'react-router-dom';
import { paths } from '~/config';
import images from '~/assets/images';
import { useMediaQuery } from '~/hooks/useMediaQuery';

const Header = () => {
    const isDesktop = useMediaQuery('(min-width: 767px)');

    return (
        <header className="sticky top-0 z-20 bg-white shadow-shadow-header">
            <div className="flex flex-row justify-between items-center px-5 lg:px-20 w-full h-20 lg:h-24">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                    <img
                        src="/logo-me-u.png"
                        alt="logo"
                        width="60"
                        height="60"
                        style={{ marginRight: '10px', borderRadius: '50%', border: '2px solid #f6ae2d' }}
                    />
                    <h1
                        style={{ margin: 0, fontSize: '30px', color: '#f6ae2d' }}
                        className="text-transparent bg-clip-text bg-gradient-to-r from-orange-400 via-yellow-400 to-yellow-300 font-beVietnamPro text-xl"
                    >
                        English
                    </h1>
                </div>
            </div>
        </header>
    );
};

export default Header;
