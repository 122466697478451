// Core
import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import cn from 'classnames';

// App
import ButtonLogin from '~/components/Button/ButtonLogin';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

// Internal
import GlobalSearch from '../GlobalSearch';
import UserInformation from '../UserInformation/UserInformation';
import { LanguagePicker } from '~/components/shared';
import { useAuthStore } from '~/stores/modules/auth';
import { NavigationHeaderBar } from '../../../components/shared';
import { Link, useLocation } from 'react-router-dom';
import { NAVIGATION_MENU } from '../../../constants/menu';
import { useTranslation } from 'react-i18next';

// Component
function Header() {
    // Hooks
    const { t: translate } = useTranslation('common');
    const isDesktop = useMediaQuery('(min-width: 767px)');
    const location = useLocation();

    // States
    const [openMenu, setOpenMenu] = useState(false);
    const [openGlobalSearch, setOpenGlobalSearch] = useState(false);
    const [displayHeader, setDisplayHeader] = useState(false);

    // Stores
    const { isLoggedIn } = useAuthStore();

    useEffect(() => {
        function handleScroll() {
            if (window.scrollY > 300) {
                setDisplayHeader(true);
            } else {
                setDisplayHeader(false);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className="sticky w-full top-0 bg-white shadow-sm z-50">
            <div className={cn('mx-auto flex flex-row justify-between items-center px-5 md:px-20 h-20 lg:h-24')}>
                <Link to={'/'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        src="/logo-me-u.png"
                        alt="logo"
                        width="60"
                        height="60"
                        style={{ marginRight: '10px', borderRadius: '50%', border: '2px solid #f6ae2d' }}
                    />
                    <h1
                        style={{ margin: 0, fontSize: '30px', color: '#f6ae2d' }}
                        className="text-lg lg:text-xl text-transparent bg-clip-text bg-gradient-to-r from-orange-400 via-yellow-400 to-yellow-300 font-beVietnamPro"
                    >
                        English
                    </h1>
                </Link>
                <div className="hidden lg:flex h-full justify-center items-center">
                    <NavigationHeaderBar />
                </div>
                <div className="h-full flex justify-end items-center" style={{ backgroundColor: '#fff' }}>
                    <div className="relative h-full flex items-center flex-row z-50 bg-white space-x-4">
                        {/* <button className="hidden search-icon -ml-1" onClick={() => setOpenGlobalSearch(true)}>
                                <SearchIcon />
                            </button> */}
                        {isLoggedIn ? <UserInformation /> : <ButtonLogin link={'/login'} />}
                        <div className="hidden lg:flex choose-language text-sm text-black opacity-80 leading-[1.57] ml-8">
                            <LanguagePicker />
                        </div>
                        <button
                            type="button"
                            className="lg:hidden flex items-center justify-center rounded-md text-gray-700"
                            onClick={() => setOpenMenu(true)}
                        >
                            <MenuOutlined className="text-2xl" />
                        </button>
                        <Drawer
                            title={null}
                            closeIcon={null}
                            open={isDesktop ? false : openMenu}
                            onClose={() => setOpenMenu(false)}
                            styles={{ body: { padding: '0px' } }}
                        >
                            <div className="w-full h-full bg-hotlink-white-f7f3ff flex flex-col">
                                <div className="w-full flex flex-row px-4 py-2 justify-between items-center bg-hotlink-blue-7158d7">
                                    <div>
                                        <CloseOutlined
                                            onClick={() => setOpenMenu(false)}
                                            className="text-xl transition-colors duration-200 hover:text-white cursor-pointer"
                                        />
                                    </div>
                                    <div className="choose-language text-sm text-black opacity-80 leading-[1.57]">
                                        <LanguagePicker />
                                    </div>
                                </div>
                                <nav>
                                    <ul className="w-full h-full bg-hotlink-white-f7f3ff flex flex-col">
                                        {NAVIGATION_MENU.map((navigationItem) => (
                                            <li
                                                key={navigationItem.path}
                                                className="flex items-center lg:h-full h-12 px-5 relative"
                                                onClick={() => setOpenMenu(false)}
                                            >
                                                <Link
                                                    to={navigationItem.path}
                                                    className={`navbar-title text-base font-BeVietnamSemiBold text-left leading-[22px] z-20 ${
                                                        navigationItem.path === location.pathname
                                                            ? 'lg:text-hotlink-yellow-f6ae2d text-hotlink-blue-309'
                                                            : 'text-hotlink-black-1a1a1a'
                                                    }`}
                                                >
                                                    {translate(navigationItem.name)}
                                                </Link>
                                                {navigationItem.path === location.pathname && (
                                                    <span className="lg:border-hotlink-yellow-f6ae2d lg:border-b-2 lg:border-r-0 lg:bg-white border-r-2 border-x-hotlink-blue-309 bg-hotlink-white-d7cceb absolute inset-0" />
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </Drawer>
                    </div>
                    <GlobalSearch open={openGlobalSearch} onClose={() => setOpenGlobalSearch(false)} />
                </div>
            </div>
        </header>
    );
}

export default Header;
