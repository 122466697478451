import axios from '../api/axios';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '~/stores/modules/auth';

const useRefreshToken = () => {
    const navigate = useNavigate();
    const { refreshToken, setAccessToken, clearUserInfo } = useAuthStore();

    const refresh = async () => {
        if (refreshToken !== null) {
            const response = await axios.post('/auth/refreshToken', {
                requestRefresh: refreshToken,
            });
            if (response.data.responseData) {
                setAccessToken(response.data.responseData.accessToken);
                return response.data.responseData.accessToken;
            } else {
                clearUserInfo();
                navigate('/login');
            }
        } else {
            clearUserInfo();
            navigate('/login');
        }
    };
    return refresh;
};

export default useRefreshToken;
