// Core
import React, { useEffect, useState } from 'react';
import cn from 'classnames';

// Antd
import { Button } from 'antd';
import { ToTopOutlined } from '@ant-design/icons';

// App
import { useMediaQuery } from '~//hooks';

const ScrollToTopButton = () => {
    const [displayScrollToTop, setDisplayScrollToTop] = useState(false);
    const isDesktop = useMediaQuery('(min-width: 640px)');

    useEffect(() => {
        function handleScroll() {
            if (window.scrollY > 800) {
                setDisplayScrollToTop(true);
            } else {
                setDisplayScrollToTop(false);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            className={cn(
                !isDesktop ? 'hidden' : displayScrollToTop ? 'block' : 'hidden',
                'group fixed right-10 bottom-10 transition-all duration-300 group-hover:text-hotlink-blue-7158d7 group-hover:border-hotlink-blue-7158d7 hover:bg-transparent',
            )}
        >
            <Button
                color="#7158d7"
                variant="outlined"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                className="flex justify-center items-center h-16 px-4 py-6"
            >
                <ToTopOutlined className="text-2xl text-gray-400 group-hover:text-hotlink-blue-7158d7 transform group-hover:scale-105 group-hover:shadow-2xl" />
            </Button>
        </div>
    );
};

export default ScrollToTopButton;
