// Core
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

// App
import { useNotification } from '~/hooks';
import { loginUser } from '~/api/userApi/userApi';
import { useAuthStore } from '~/stores/modules/auth';

// Custom Hook
const useLogin = ({ onSuccess = () => {}, onError = () => {} } = {}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { success, warning } = useNotification();
    const { updateUserInfo, updateIsLoggedIn, updateAccessToken, updateRefreshToken } = useAuthStore();

    return useMutation({
        mutationFn: (values) => loginUser(values),
        onSuccess: async (loginResponse) => {
            const from = location.state?.from?.pathname || '/';

            updateIsLoggedIn(true);
            updateAccessToken(loginResponse.accessToken);
            updateRefreshToken(loginResponse.refreshToken);
            updateUserInfo({ ...loginResponse.userInfo });

            success({
                message: 'Thành Công',
                description: loginResponse.description,
            });

            if (loginResponse.userInfo.role === 'ADMIN') {
                navigate('/admin');
            } else {
                navigate(from, { replace: true });
            }

            onSuccess(loginResponse);
        },
        onError: (error) => {
            const errorResponse = error.response.data;

            warning({
                message: 'Thất bại',
                description: errorResponse.message ?? 'Đăng nhập thất bại',
            });
            onError(error);
        },
    });
};

export default useLogin;
