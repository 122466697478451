// Core
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

// Antd
import { Space } from 'antd';

// App
import { NavigationMenu, NavigationMenuItem, NavigationMenuList } from '~/components/ui';
import { NAVIGATION_MENU } from '../../../constants/menu';

const NavigationHeaderBar = () => {
    const navigate = useNavigate();
    const { t: translation } = useTranslation('common');

    return (
        <NavigationMenu>
            <NavigationMenuList>
                {NAVIGATION_MENU.map((linkItem, index) => (
                    <NavigationMenuItem
                        key={index}
                        id={index}
                        triggerElement={
                            <div
                                // className={cn(
                                //     "group relative h-full flex justify-center items-center px-4 cursor-pointer after:content-[''] after:absolute after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:w-0 after:h-[2px] after:bg-hotlink-yellow-f6ae2d after:transition-all after:duration-300 hover:after:w-full",
                                //     location.pathname === linkItem.path && 'after:w-full',
                                // )}
                                className="group h-full flex justify-center items-center px-4 cursor-pointer"
                            >
                                <NavLink
                                    to={linkItem.path}
                                    className={({ isActive }) =>
                                        cn(
                                            'flex items-center text-base font-medium font-beVietnamPro leading-4 transition-colors duration-300',
                                            isActive
                                                ? 'text-hotlink-yellow-f6ae2d'
                                                : 'text-gray-700 hover:text-hotlink-yellow-f6ae2d',
                                        )
                                    }
                                >
                                    <Space>
                                        {linkItem.icon && <linkItem.icon className="mr-2 text-lg" />}
                                        {translation(linkItem.name)}
                                    </Space>
                                </NavLink>
                            </div>
                        }
                    >
                        {linkItem.children && (
                            <div>
                                {/* Section Title */}
                                <h2 className="inline-block text-xl font-bold font-beVietnamPro text-gray-900 mb-10 relative after:absolute after:content-[''] after:w-full after:h-1 after:bg-yellow-500 after:rounded-full after:left-0 after:bottom-[-10px]">
                                    {translation(linkItem.name)} :
                                </h2>

                                {/* Items Container */}
                                <div className="flex flex-wrap gap-8">
                                    {linkItem.children.map((childrenItem, childrenIndex) => (
                                        <div
                                            key={childrenIndex}
                                            className="group flex-1 flex items-center gap-4 p-2 bg-white rounded hover:shadow-xl transition-shadow duration-300 hover:bg-gradient-to-r from-yellow-400 to-orange-400 cursor-pointer"
                                            onClick={() => navigate(childrenItem.path)}
                                        >
                                            {/* Icon */}
                                            <div className="text-5xl text-yellow-500 group-hover:text-white transition-colors duration-300">
                                                {childrenItem.icon}
                                            </div>

                                            {/* Text Content */}
                                            <div>
                                                <p className="text-xl font-semibold text-gray-800 group-hover:text-white transition-colors duration-300">
                                                    {translation(childrenItem.name)}
                                                </p>
                                                <p className="mt-1 text-sm text-gray-500 group-hover:text-white transition-colors duration-300">
                                                    {childrenItem.description || 'Discover more about this topic.'}
                                                </p>
                                            </div>

                                            {/* Arrow */}
                                            <div className="ml-auto text-yellow-500 group-hover:text-white transition-transform duration-300 transform group-hover:translate-x-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M9 5l7 7-7 7"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </NavigationMenuItem>
                ))}
            </NavigationMenuList>
        </NavigationMenu>
    );
};

export default NavigationHeaderBar;
