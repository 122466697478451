import Header from '~/layouts/components/Header';
import Footer from '~/layouts/components/Footer';
import { Outlet } from 'react-router-dom';
import { ScrollToTopButton } from '../../components/shared';

function DefaultLayout() {
    return (
        <div className="grid min-h-dvh grid-rows-[1fr_auto]">
            <div className="grid grid-rows-[auto_1fr]">
                {/* Header */}
                <Header />
                {/* Main Content */}
                <main className="min-w-screen overflow-hidden">
                    <Outlet />
                </main>
            </div>

            {/* Footer */}
            <Footer />

            {/* Scroll To Top Button */}
            <ScrollToTopButton />
        </div>
    );
}

export default DefaultLayout;
