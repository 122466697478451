// Core
import React, { useCallback, useMemo } from 'react';

// Antd
import { Carousel as CarouselAntd } from 'antd';

// App
import { useMediaQuery } from '~/hooks/useMediaQuery';

// Constant
const NUMBER_ITEM_DESKTOP = 4;
const NUMBER_ITEM_MOBILE = 1;

const Carousel = ({ dots = false, arrows = false, autoplay = false, autoplaySpeed = 5000, children }) => {
    const isDesktop = useMediaQuery('(min-width: 767px)');

    // Function to group items
    const handleGroupData = useCallback((itemList, groupSize) => {
        const groupedList = [];
        for (let i = 0; i < itemList.length; i += groupSize) {
            groupedList.push(itemList.slice(i, i + groupSize));
        }
        return groupedList;
    }, []);

    const groupedItems = useMemo(() => {
        const itemsArray = React.Children.toArray(children);
        return handleGroupData(itemsArray, isDesktop ? NUMBER_ITEM_DESKTOP : NUMBER_ITEM_MOBILE);
    }, [children, isDesktop, handleGroupData]);

    return (
        <CarouselAntd dots={dots} arrows={arrows} autoplay={autoplay} autoplaySpeed={autoplaySpeed}>
            {groupedItems.map((group, index) => (
                <div key={index} className="py-2">
                    <div
                        className={`grid grid-cols-${NUMBER_ITEM_MOBILE} lg:grid-cols-${NUMBER_ITEM_DESKTOP} gap-2 lg:gap-4`}
                    >
                        {group.map((item, itemIndex) => (
                            <div key={itemIndex}>{item}</div>
                        ))}
                    </div>
                </div>
            ))}
        </CarouselAntd>
    );
};

export default Carousel;
