// Core
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// Store
const useAuthStore = create(
    persist(
        (set) => ({
            userInfo: null,
            accessToken: null,
            refreshToken: null,
            hasRememberMe: false,
            isLoggedIn: false,
            _hasHydrated: false,

            updateHasRememberMe: (hasRememberMe) => {
                set(() => ({ hasRememberMe }));
            },

            updateIsLoggedIn: (isLoggedIn) => {
                set(() => ({ isLoggedIn: isLoggedIn }));
            },

            updateUserInfo: (userInfo) => {
                set(() => ({ userInfo }));
            },

            updateAccessToken: (token) => {
                set(() => ({ accessToken: token }));
            },

            updateRefreshToken: (token) => {
                set(() => ({ refreshToken: token }));
            },

            clearUserInfo: () => {
                set({
                    userInfo: null,
                    accessToken: null,
                    refreshToken: null,
                    isLoggedIn: false,
                });
            },

            setHasHydrated: (state) => {
                set(() => ({ _hasHydrated: state }));
            },
        }),
        {
            name: 'auth-storage',
            partialize: (state) =>
                state.hasRememberMe
                    ? {
                          userInfo: state.userInfo,
                          accessToken: state.accessToken,
                          refreshToken: state.refreshToken,
                          hasRememberMe: state.hasRememberMe,
                          isLoggedIn: state.isLoggedIn,
                      }
                    : {
                          hasRememberMe: state.hasRememberMe,
                          accessToken: state.accessToken,
                          refreshToken: state.refreshToken,
                          isLoggedIn: state.isLoggedIn,
                          userInfo: state.userInfo,
                      },
            onRehydrateStorage: () => (state, error) => {
                if (error) {
                    console.error('Error during hydration:', error);
                } else if (state) {
                    state.setHasHydrated(true);
                }
            },
        },
    ),
);

export default useAuthStore;
