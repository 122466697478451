import React, { useCallback, useState } from 'react';
import { useNavigation } from '../NavigationMenu';
import { NavigationMenuTrigger } from './components';

const NavigationMenuItem = ({ id, triggerElement, className, children }) => {
    const { menuList, updateActiveItem } = useNavigation();
    const [menuState, setMenuState] = useState('closed');
    const [isHoverContent, setIsHoverContent] = useState(false);

    const isActive = menuList.find((item) => item.id === id)?.active;

    const handleClose = useCallback(() => {
        setTimeout(() => {
            if (!isHoverContent) {
                setMenuState('closed');
                updateActiveItem(null);
            }
        }, 200);
    }, [isHoverContent, updateActiveItem]);

    const handleMouseEnter = () => {
        console.log('hover');
        if (!children) return;
        setMenuState('open');
        updateActiveItem(id);
    };

    const handleMouseLeave = () => {
        console.log('leave');
        if (!children) return;

        handleClose();
    };

    const handleContentMouseEnter = () => {
        setIsHoverContent(true);
    };

    const handleContentMouseLeave = () => {
        setIsHoverContent(false);
        handleClose();
    };

    return (
        <li className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <NavigationMenuTrigger>{triggerElement}</NavigationMenuTrigger>
            {(isActive || menuState === 'open') && (
                <div
                    data-state={menuState}
                    onMouseEnter={handleContentMouseEnter}
                    onMouseLeave={handleContentMouseLeave}
                    className={`content absolute inset-0 top-full ${
                        menuState === 'open' ? 'opacity-100 visible' : 'opacity-0 invisible'
                    } transition-all duration-300`}
                >
                    <div className="relative min-h-[128px]">
                        <div className="absolute left-0 top-0 bg-gradient-to-r from-white to-gray-50 rounded-b-xl shadow-sm p-8 w-full h-auto max-w-6xl border-t-2 border-hotlink-yellow-f6ae2d">
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </li>
    );
};

export default NavigationMenuItem;
