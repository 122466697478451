import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ConfigProvider, Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from '~/hooks/useMediaQuery';
import { Header } from './components';
import { useTranslation } from 'react-i18next';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { NAVIGATION_ADMIN_MENU } from '../../constants/menu';

const { Content, Sider } = Layout;

function AdminLayout() {
    const { t: translate } = useTranslation('common');
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 769px)');

    // States
    const [collapsed, setCollapsed] = useState(false);

    // Methods
    const handleMenuClick = (item) => {
        const selectedParentItem = NAVIGATION_ADMIN_MENU.find((menuItem) =>
            menuItem.children
                ? menuItem.children.find((itemChild) => itemChild.key === item.key)
                : menuItem.key === item.key,
        );

        if (selectedParentItem.children) {
            navigate(selectedParentItem.children.find((menuItem) => menuItem.key === item.key).link);
        } else navigate(selectedParentItem.link);
    };

    const handleToggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className="overflow-hidden">
            <Header />
            <Layout>
                {isDesktop && (
                    <Sider width={256} collapsed={isDesktop ? collapsed : true}>
                        <div className="min-h-screen h-full flex flex-col">
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Menu: {
                                            itemColor: '#7158d7',
                                            itemSelectedColor: '#7158d7',
                                            itemActiveBg: '#7158d7',
                                            itemHoverColor: '#7158d7',
                                            itemSelectedBg: '#aa9de3',
                                            itemBorderRadius: 0,
                                            iconSize: 20,
                                        },
                                    },
                                }}
                            >
                                <Menu
                                    mode="inline"
                                    onClick={handleMenuClick}
                                    className="flex-1 h-full font-beVietnamPro font-bold text-sm text-hotlink-black-333333"
                                >
                                    {NAVIGATION_ADMIN_MENU.map((item) => {
                                        if (item?.children) {
                                            return (
                                                <Menu.SubMenu
                                                    key={item.key}
                                                    title={
                                                        <div className="flex items-center py-8 text-lg font-medium font-BeVietNamBold rounded-none">
                                                            {item.icon}
                                                            <span>{translate(`admin.${item.name}`)}</span>
                                                        </div>
                                                    }
                                                >
                                                    {item.children.map((childItem) => (
                                                        <Menu.Item
                                                            key={childItem.key}
                                                            className="flex justify-center items-center px-2 py-8  text-lg font-medium font-BeVietNamBold rounded-none"
                                                        >
                                                            {translate(`admin.${childItem.name}`)}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu.SubMenu>
                                            );
                                        } else {
                                            return (
                                                <Menu.Item
                                                    key={item.key}
                                                    icon={item.icon}
                                                    className="flex justify-center items-center px-2 py-8  text-lg font-medium font-BeVietNamBold rounded-none"
                                                >
                                                    <div>{translate(`admin.${item.name}`)}</div>
                                                </Menu.Item>
                                            );
                                        }
                                    })}
                                </Menu>
                            </ConfigProvider>
                            <div className="flex justify-center items-center bg-white">
                                <div onClick={handleToggleCollapsed} className="mt-8 cursor-pointer">
                                    {collapsed ? (
                                        <MenuUnfoldOutlined className="text-xl text-hotlink-blue-7158d7 hover:text-hotlink-blue-7158d7/50" />
                                    ) : (
                                        <MenuFoldOutlined className="text-xl text-hotlink-blue-7158d7 hover:text-hotlink-blue-7158d7/50" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Sider>
                )}
                <Layout className="relative flex flex-col justify-start items-start gap-2 lg:gap-4 py-2 lg:py-4 px-2 lg:px-6 font-BeVietNamRegular bg-hotlink-white-f8fffc">
                    <Content className="w-full">
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
}

export default AdminLayout;
