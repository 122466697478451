// Core
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { WarningOutlined } from '@ant-design/icons';

// App
import { authLogOut } from '~/api/userApi/userApi';
import useNotification from '../../useNotification';
import { useAuthStore } from '~/stores/modules/auth';

// Internal

// Hook
const useLogout = () => {
    // Hooks
    const navigate = useNavigate();
    const notificationApi = useNotification();

    // Stores
    const { clearUserInfo } = useAuthStore();

    return useMutation({
        mutationFn: authLogOut,
        onSuccess: () => {
            clearUserInfo();
            navigate('/login');
        },
        onError: () => {
            notificationApi.open({
                message: 'Lỗi',
                description: 'Đã xảy ra lỗi đăng xuất thất bại',
                placement: 'top',
                showProgress: true,
                pauseOnHover: true,
                icon: <WarningOutlined style={{ color: 'yellow' }} />,
            });
        },
    });
};

export default useLogout;
