// Core
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';

// App
import images from '~/assets/images';

// Internal
import './Contact.scss';

// Component
const Contact = (props) => {
    const { t } = useTranslation('common');
    const handleSubmit = (values) => {
        console.log(values);
    };

    return (
        <div
            className={`contact-page relative lg:h-[658px] h-[950px] font-beVietnamPro ${
                props.page !== 'home' ? 'bg-hotlink-white-f7f3ff' : ''
            }`}
        >
            <div
                className={`bg-hotlink-white-f7f3ff lg:w-[988px] w-full h-full relative`}
                // data-aos="fade-right"
                // data-aos-duration="1000"
            >
                <span className="absolute lg:w-[562px] w-4/5 lg:top-[87px] top-12 left-[80px] text-[35px] lg:text-[40px] text-3xl leading-normal tracking-[1.2px] text-left text-hotlink-blue-309 first-letter:capitalize">
                    {t('contact_us')}
                </span>

                <span className="absolute lg:w-[420px] w-1/2 top-[170px] lg:left-[200px] left-40 self-stretch lg:text-base text-sm leading-normal tracking-[0.48px] text-justify text-hotlink-black-4d4d4d">
                    "Listen to what our students have to say about MeU English - our e-learning platform designed to
                    improve English vocabulary and prepare for exams. Your feedback is important to us and helps us
                    improve our website to serve you better ."
                </span>
                <img
                    className="absolute lg:bottom-[-5px] bottom-[62%] lg:left-[54px] left-2 object-contain h-1/5 lg:h-2/3"
                    src={images.contactUs}
                    alt="Contact Us"
                ></img>
            </div>

            <Form
                onFinish={handleSubmit}
                className="absolute lg:w-[550px] w-full flex flex-col justify-start items-stretch gap-2 lg:py-10 py-5 lg:px-14 px-7 bg-hotlink-purple-7158d7 top-[45%] lg:top-1/2 lg:right-[80px] lg:-translate-y-1/2 shadow-contact rounded-[20px]"
            >
                <div className="flex flex-col justify-start items-center gap-3 sm:mx-2">
                    <span className="lg:text-4xl text-3xl leading-normal tracking-[1.08px] text-white first-letter:capitalize">
                        {t('opportunity')}
                    </span>

                    <div className="flex justify-between gap-[24px] self-stretch">
                        <Form.Item
                            name="fullname"
                            rules={[
                                { required: true, message: t('fullname_required') },
                                {
                                    pattern: /^[A-Za-zÀ-ỹ\s]+$/,
                                    message: 'Họ và Tên không được chứa số và ký tự đặc biệt',
                                },
                            ]}
                            className="w-full"
                        >
                            <Input
                                className="w-full h-[56px] py-2 px-3 rounded-[8px] border-contact bg-white text-base leading-normal text-hotlink-black-000000d9 text-left font-BeVietNamRegular"
                                placeholder={t('fullname')}
                            />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            rules={[
                                { required: true, message: t('phone_required') },
                                { pattern: /^[0-9]{10,11}$/, message: 'Vui lòng khớp với định dạng được yêu cầu.' },
                            ]}
                            className="w-full"
                        >
                            <Input
                                className="w-full h-[56px] py-2 px-3 rounded-[8px] border-contact bg-white text-base leading-normal text-hotlink-black-000000d9 text-left font-BeVietNamRegular"
                                placeholder={t('phone')}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: t('email_required') },
                            { type: 'email', message: 'Định dạng Email không đúng.' },
                        ]}
                        className="w-full"
                    >
                        <Input
                            className="w-full h-[56px] py-2 px-3 rounded-[8px] border-contact bg-white text-base leading-normal text-hotlink-black-000000d9 text-left font-BeVietNamRegular"
                            placeholder="Email"
                        />
                    </Form.Item>

                    <Form.Item
                        name="role"
                        rules={[{ required: true, message: 'Vui lòng nhập vai trò của bạn.' }]}
                        className="w-full"
                    >
                        <Input
                            className="w-full h-[56px] py-2 px-3 rounded-[8px] border-contact bg-white text-base leading-normal text-hotlink-black-000000d9 text-left font-BeVietNamRegular"
                            placeholder={t('what_role')}
                        />
                    </Form.Item>

                    <Form.Item
                        name="question"
                        rules={[{ required: true, message: 'Vui lòng nhập câu hỏi của bạn.' }]}
                        className="w-full"
                    >
                        <Input
                            className="w-full h-[56px] py-2 px-3 rounded-[8px] border-contact bg-white text-base leading-normal text-hotlink-black-000000d9 text-left font-BeVietNamRegular"
                            placeholder={t('need_answer')}
                        />
                    </Form.Item>
                </div>

                <Button
                    htmlType="submit"
                    className="w-full lg:h-16 h-14 py-2 px-4 rounded-lg shadow-contact bg-hotlink-yellow-f6ae2d text-xl leading-[1.2] text-white uppercase font-bold"
                >
                    {t('reserve_spot')}
                </Button>
            </Form>
        </div>
    );
};

export default Contact;
