import { QueryClient } from '@tanstack/react-query';

// Query client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            placeholderData: (previousData) => previousData,
        },
    },
});

export default queryClient;
