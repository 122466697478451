import { useQuery } from '@tanstack/react-query';
import { getUserInformation } from '../../../api/userApi/userApi';
import useAxiosPrivate from '../../useAxiosPrivate';

const useUserInfomation = (enabled) => {
    const axiosPrivate = useAxiosPrivate();

    return useQuery({
        queryKey: ['user-info'],
        queryFn: () => getUserInformation(axiosPrivate),
        enabled: enabled,
    });
};

export default useUserInfomation;
