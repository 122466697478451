import { useMutation } from '@tanstack/react-query';

import { authSendOTP } from '../../../api/userApi/userApi';
import useNotification from '../../useNotification';

const getErrorMessage = (error) => {
    if (error.response?.data?.message) return error.response.data.message;
    if (error.message) return error.message;
    return 'Đã xảy ra lỗi trong quá trình gửi mail';
};

const useForgotPasswordMutation = ({ onSuccess = () => {}, onError = () => {} } = {}) => {
    const { success, warning } = useNotification();

    return useMutation({
        mutationFn: (email) => authSendOTP(email),
        onSuccess: (forgotPasswordResponse) => {
            success({
                message: 'Thành công',
                description: forgotPasswordResponse.message,
            });

            if (onSuccess) onSuccess(forgotPasswordResponse);
        },
        onError: (error) => {
            warning({
                message: 'Thất bại',
                description: getErrorMessage(error),
            });

            if (onError) onError(error);
        },
    });
};

export default useForgotPasswordMutation;
