// Core
import { useCallback, useState } from 'react';

// App
import { registerUser } from '~/api/userApi/userApi';
import useNotification from '../../useNotification';

const useRegister = () => {
    // Hooks
    const notificationApi = useNotification();

    // States
    const [isLoading, setIsLoading] = useState(false);

    const register = useCallback(
        async (values) => {
            setIsLoading(true);
            const registerResponse = await registerUser(values);
            setIsLoading(false);
            notificationApi.success({
                message: 'Thành Công',
                description: registerResponse.description,
            });
        },
        [notificationApi],
    );

    return { register, isLoading };
};

export default useRegister;
