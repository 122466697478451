import { useState, useEffect, useRef } from 'react';

const useCountdown = (initialTime, onCountdownEnd = () => {}) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const timerRef = useRef(null);

    useEffect(() => {
        if (timeLeft > 0) {
            timerRef.current = setInterval(() => {
                setTimeLeft((prev) => prev - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            if (onCountdownEnd) onCountdownEnd();
            clearInterval(timerRef.current);
        }

        return () => clearInterval(timerRef.current);
    }, [timeLeft, onCountdownEnd]);

    const resetTimer = (newTime) => {
        clearInterval(timerRef.current);
        setTimeLeft(newTime);
    };

    return { timeLeft, resetTimer };
};

export default useCountdown;
