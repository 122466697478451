import { redirect } from 'react-router-dom';
import { useAuthStore } from '~/stores/modules/auth';

const requireAuth = async () => {
    const isLoggedIn = useAuthStore.getState().isLoggedIn;
    if (!isLoggedIn) {
        console.log('User not authenticated, redirecting to login');
        throw redirect('/login');
    }
    return null;
};

export default requireAuth;
