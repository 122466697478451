import UserInformation from '../../../components/UserInformation/UserInformation';
import images from '~/assets/images';
import { NotificationBell } from './components';
import { LanguagePicker } from '~/components/shared';
import { Row, Col, Drawer } from 'antd';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { useState } from 'react';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { NAVIGATION_ADMIN_MENU } from '../../../../constants/menu';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
    // Hooks
    const { t: translate } = useTranslation('common');
    const isDesktop = useMediaQuery('(min-width: 769px)');
    const location = useLocation();

    // States
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <header className="bg-gradient-to-r from-blue-500 to-indigo-600 w-full shadow-lg py-4">
            <div className="px-8">
                <Row justify="space-between" align="middle" gutter={24}>
                    {/* Logo Section */}
                    <Col xs={12} sm={12} md={8}>
                        <div className="flex items-center">
                            <div className="flex items-center">
                                <img className="h-14 w-auto" src={images.logo} alt="MeU" />
                            </div>
                        </div>
                    </Col>

                    {/* Right Section: Notification, User Info, Language */}
                    <Col xs={12} sm={12} md={16} className="flex justify-end items-center gap-4 md:gap-6">
                        {/* Notification Bell */}
                        {isDesktop && <NotificationBell />}

                        {/* User Information */}
                        <div className="relative">
                            <UserInformation />
                        </div>

                        {/* Language Picker */}
                        <div className="text-sm font-medium text-white opacity-90 hover:opacity-100 cursor-pointer">
                            <LanguagePicker />
                        </div>

                        {!isDesktop && (
                            <div className="ml-4">
                                <button
                                    type="button"
                                    className="flex items-center justify-center rounded-md text-white"
                                    onClick={() => setOpenMenu(true)}
                                >
                                    <MenuOutlined className="text-2xl" />
                                </button>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
            <Drawer
                title={null}
                closeIcon={null}
                open={isDesktop ? false : openMenu}
                onClose={() => setOpenMenu(false)}
                styles={{ body: { padding: '0px' } }}
            >
                <div className="w-full h-full bg-hotlink-white-f7f3ff flex flex-col">
                    <div className="w-full flex flex-row px-4 py-2 justify-between items-center bg-hotlink-blue-7158d7">
                        <div>
                            <CloseOutlined
                                onClick={() => setOpenMenu(false)}
                                className="text-xl transition-colors duration-200 hover:text-white cursor-pointer"
                            />
                        </div>
                        <div className="choose-language text-sm text-black opacity-80 leading-[1.57]">
                            <LanguagePicker />
                        </div>
                    </div>
                    <nav>
                        <ul className="w-full h-full bg-hotlink-white-f7f3ff flex flex-col">
                            {NAVIGATION_ADMIN_MENU.map((navigationItem) => (
                                <li
                                    key={navigationItem.link}
                                    className="flex items-center lg:h-full h-12 px-5 relative"
                                    onClick={() => setOpenMenu(false)}
                                >
                                    <Link
                                        to={navigationItem.link}
                                        className={`navbar-title text-base font-BeVietnamSemiBold text-left leading-[22px] z-20 ${
                                            navigationItem.link === location.pathname
                                                ? 'lg:text-hotlink-yellow-f6ae2d text-hotlink-blue-309'
                                                : 'text-hotlink-black-1a1a1a'
                                        }`}
                                    >
                                        {translate(`admin.${navigationItem.name}`)}
                                    </Link>
                                    {navigationItem.link === location.pathname && (
                                        <span className="lg:border-hotlink-yellow-f6ae2d lg:border-b-2 lg:border-r-0 lg:bg-white border-r-2 border-x-hotlink-blue-309 bg-hotlink-white-d7cceb absolute inset-0" />
                                    )}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </Drawer>
        </header>
    );
};

export default Header;
